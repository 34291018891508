import React, { useState, useEffect } from "react";
import FrontCard from "./components/card/Front";
import BackCard from "./components/card/Back";
import Faceliveness from "./components/face/Faceliveness";
import Confirm from "./components/face/Confirm";
import "react-tabs/style/react-tabs.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Select from 'react-select'
import * as tf from "@tensorflow/tfjs";
import axios from "axios";
import { messageModelCard, URL_API, FIRST } from "../src/constants";

const App = () => {
  const [isIOS, setIsIOS] = useState();
  const [isMobile, setIsMobile] = useState();
  const [phase, setPhase] = useState(1);
  const [confirmData, setConfirmData] = useState();
  const [modelFace, setModelFace] = useState();
  const modelURL = "../../model_face/model.json";
  const [sessionId, setSessionId] = useState("session_id_default");

  const getConfirmData = (data) => {
    setConfirmData(data);
  };

  const getPhaseStatus = (status) => {
    setPhase(status);
  };

  useEffect(() => {
    checkIsMobile();
  }, []);

  const [activePhase, setActivePhase] = useState({
    phase1: true,
    phase2: false,
    phase3: false,
    phase4: false,
  });

  const randomSessionId = async() => {
    let formData2 = new FormData();
    formData2.append("key", "source");
    formData2.append("source", "Spinel");
    formData2.append("description", "Spinel");
    formData2.append("type", "text");
    const url = URL_API + "start_session";

    const res = await axios({
       method: "post",
       url: url,
       data: formData2,
       headers: { "Content-Type": "multipart/form-data",'Authorization':'Token e240185e82954dca453a265c84433d3cd4bf9601' },
     })
     console.log({res})
     let result = "";
     let characters =
       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
     let charactersLength = characters.length;
     for (let i = 0; i < 10; i++) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     console.log(result)
    setSessionId(res?.data?.output.id);
  };

  let cameraComponent;

  const goToPhase1 = () => {
    setPhase(1);
    setActivePhase({
      ...activePhase,
      phase1: true,
      phase2: false,
      phase3: false,
      phase4: false,
    });
  };

  const goToPhase2 = () => {
    // if (activePhase.phase1 === true && activePhase.phase2 === true) {
    setPhase(2);
    setActivePhase({
      ...activePhase,
      phase2: true,
      phase3: false,
      phase4: false,
    });
    // }
  };

  const goToPhase3 = () => {
    if (activePhase.phase2 === true && activePhase.phase3 === true) {
      setPhase(3);
      setActivePhase({
        ...activePhase,
        phase3: true,
        phase4: false,
      });
    }
  };

  //Init model face
  const initModelFace = async () => {
    let modelFace = await tf.loadGraphModel(modelURL);
    setModelFace(modelFace);
  };

  // const loadBlazeModel = async () => {
  //   let blazeModel = await blazeface.load();
  //   setBlazeModel(blazeModel);
  // };

  useEffect(() => {
    const initModel = async () => {
      randomSessionId();
      // await loadBlazeModel();
      await initModelFace();
    };
    initModel();
  }, []);

  switch (phase) {
    case 1:
      cameraComponent = (
        <FrontCard
          goNextPhase={getPhaseStatus}
          isMobile={isMobile}
          sessionId={sessionId}
        />
      );
      break;
    case 2:
      cameraComponent = (
        <BackCard
          goNextPhase={getPhaseStatus}
          isMobile={isMobile}
          sessionId={sessionId}
        />
      );
      break;
    case 3:
      cameraComponent = (
        <Faceliveness
          goNextPhase={getPhaseStatus}
          confirmData={getConfirmData}
          isMobile={isMobile}
          isIOS={isIOS}
          modelFace={modelFace}
          // blazeModel={blazeModel}
          sessionId={sessionId}
        />
      );
      break;
    case 4:
      cameraComponent = <Confirm confirmData={confirmData} />;
      break;
    default:
      break;
  }

  useEffect(() => {
    switch (phase) {
      case 1:
        setActivePhase({
          // ...activePhase,
          phase1: true,
          phase2: false,
          phase3: false,
          phase4: false,
        });
        break;
      case 2:
        setActivePhase({
          ...activePhase,
          phase2: true,
          phase3: false,
          phase4: false,
        });
        break;
      case 3:
        setActivePhase({
          ...activePhase,
          phase3: true,
          phase4: false,
        });
        break;
      case 4:
        setActivePhase({
          ...activePhase,
          phase4: true,
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase]);

  const checkIsMobile = () => {
    let agent = navigator.userAgent;
    let isWebkit = agent.indexOf("AppleWebKit") > 0;
    // let isIPad = agent.indexOf("iPad") > 0;
    let isIOS = agent.indexOf("iPhone") > 0 || agent.indexOf("iPod") > 0;
    let isAndroid = agent.indexOf("Android") > 0;
    let isNewBlackBerry =
      agent.indexOf("AppleWebKit") > 0 && agent.indexOf("BlackBerry") > 0;
    let isWebOS = agent.indexOf("webOS") > 0;
    let isWindowsMobile = agent.indexOf("IEMobile") > 0;
    let isSmallScreen =
      window.screen.width < 767 || (isAndroid && window.screen.width < 1000);
    let isUnknownMobile = isWebkit && isSmallScreen;
    let isMobile =
      isIOS ||
      isAndroid ||
      isNewBlackBerry ||
      isWebOS ||
      isWindowsMobile ||
      isUnknownMobile;
    setIsIOS(isIOS);
    setIsMobile(isMobile);
    // let isTablet = isIPad || (isMobile && !isSmallScreen);
  };

  return (
    <div className="App">
      <div className="stepbar">
        <div className="step-button-group">
          <div
            className={
              activePhase.phase1 ? "step-button active" : "step-button"
            }
          >
            <div
              className={
                activePhase.phase1
                  ? "sub-step-button sub-active"
                  : "sub-step-button"
              }
              onClick={goToPhase1}
            ></div>
          </div>
        </div>
        <div className="step-button-group">
          <div
            className={
              activePhase.phase2 ? "connect-active" : "progress-connect"
            }
          ></div>
          <div
            className={
              activePhase.phase2 ? "step-button active" : "step-button"
            }
          >
            <div
              className={
                activePhase.phase2
                  ? "sub-step-button sub-active"
                  : "sub-step-button"
              }
              onClick={goToPhase2}
            ></div>
          </div>
        </div>
        <div className="step-button-group">
          <div
            className={
              activePhase.phase3 ? "connect-active" : "progress-connect"
            }
          ></div>
          <div
            className={
              activePhase.phase3 ? "step-button active" : "step-button"
            }
          >
            <div
              className={
                activePhase.phase3
                  ? "sub-step-button sub-active"
                  : "sub-step-button"
              }
              onClick={goToPhase3}
            ></div>
          </div>
        </div>
        <div className="step-button-group">
          <div
            className={
              activePhase.phase4 ? "connect-active" : "progress-connect"
            }
          ></div>
          <div
            className={
              activePhase.phase4 ? "step-button active" : "step-button"
            }
          >
            <div
              className={
                activePhase.phase4
                  ? "sub-step-button sub-active"
                  : "sub-step-button"
              }
            ></div>
          </div>
        </div>
      </div>

      <div className="camera-area">{cameraComponent}</div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default App;
