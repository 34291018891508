/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-feather";
import face_nor_lap from "../../static/image/Face_lap.png";
import face_avail_lap from "../../static/image/Face_avail_lap.png";
import face_nor_mobile from "../../static/image/Face_mobile.png";
import face_avail_mobile from "../../static/image/Face_avail_mobile.png";
import * as tf from "@tensorflow/tfjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_API } from "../../constants";
import axios from "axios";
import Webcam from "react-webcam";

const Faceliveness = ({
  goNextPhase,
  confirmData,
  isMobile,
  isIOS,
  modelFace,
  sessionId,
}) => {
  const [cameraAvail, setCameraAvail] = useState(false);
  const [faceNumber, setFaceNumber] = useState();
  const [faceRatioList, setFaceRatioList] = useState();
  const [faceModelLabelArray, setFaceModelLabelArray] = useState([]);
  //For progressbar
  const [phaseStatus, setPhaseStatus] = useState({
    center: "nor",
    left: "nor",
    right: "nor",
  });
  const [blazeMessage, setBlazeMessage] = useState("Hãy làm theo hướng dẫn");
  const [orderMessage, setOrderMessage] = useState("Giữ thẳng");
  const [isStarted, setIsStarted] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [videoFile, setVideoFile] = useState();
  // const [blobArray, setBlobArray] = useState();
  const [directionArray, setDirectionArray] = useState();
  const [videoConstraints, setVideoConstraints] = useState({
    width: isMobile ? 320 : 640,
    height: isMobile ? 320 : 480,
    facingMode: "user",
  });
  const [normalLayer, setNormalLayer] = useState(face_nor_lap);
  const [availLayer, setAvailLayer] = useState(face_avail_lap);
  const forceUpdate = useCallback(() => {
    setBlazeMessage("Hãy làm theo hướng dẫn");
    setOrderMessage("Giữ thẳng");
    setCameraAvail(false);
    setIsStarted(false);
    setIsStopped(false);
    setPhaseStatus({
      ...phaseStatus,
      center: "nor",
      left: "nor",
      right: "nor",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let faceModelLabelList = [];
  let countFaceArray = [];
  let faceRatioArray = [];
  let directionList = [];
  let ctx,
    video1,
    face,
    ctx_face,
    canvasCtx,
    canvasElement,
    canvas,
    // blazeModel,
    faceInFrame,
    pixelData,
    faceRatio,
    direction;

  //Change video size if isMobile
  useEffect(() => {
    let agent = navigator.userAgent;
    let isWebkit = agent.indexOf("AppleWebKit") > 0;
    let isIPad = agent.indexOf("iPad") > 0;
    let isIOS = agent.indexOf("iPhone") > 0 || agent.indexOf("iPod") > 0;
    let isAndroid = agent.indexOf("Android") > 0;
    let isNewBlackBerry =
      agent.indexOf("AppleWebKit") > 0 && agent.indexOf("BlackBerry") > 0;
    let isWebOS = agent.indexOf("webOS") > 0;
    let isWindowsMobile = agent.indexOf("IEMobile") > 0;
    let isSmallScreen =
      window.screen.width < 767 || (isAndroid && window.screen.width < 1000);
    let isUnknownMobile = isWebkit && isSmallScreen;
    let isMobile =
      isIOS ||
      isAndroid ||
      isNewBlackBerry ||
      isWebOS ||
      isWindowsMobile ||
      isUnknownMobile;
    let isTablet = isIPad || (isMobile && !isSmallScreen);
    if (isTablet || isMobile) {
      setVideoConstraints({ ...videoConstraints, width: 320, height: 320 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      setAvailLayer(face_avail_mobile);
      setNormalLayer(face_nor_mobile);
    }
  }, [isMobile]);
  useEffect(() => {
    //Run model, setup argument status
    const setUpPage = async () => {
      setBlazeMessage("Thiết lập camera");
      setBlazeMessage("Đang khởi động model");
      if (modelFace) {
        setTimeout(async () => {
          getCanvas();
          // await renderPrediction();
          setBlazeMessage("Có thể bấm quay");
          countFrame();
          addFaceDirectionToArray();
          addLabelToArray(modelFace);
        }, 2000);
      } else {
        console.log("error blaze model");
        toast.error("No model");
      }
    };
    setUpPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let overlay = document.getElementById("card-overlayer");
    if (overlay) {
      if (videoConstraints.width <= videoConstraints.height) {
        overlay.style.width = 320 + "px";
        overlay.style.height = 320 + "px";
      } else {
        overlay.style.width = 640 + "px";
        overlay.style.height = 480 + "px";
      }
    }
  }, [videoConstraints]);

  const goConfirmPhase = () => {
    goNextPhase(4);
  };

  //Setup for canva
  const getCanvas = () => {
    video1 = document.querySelector("video");
    face = document.getElementById("face");
    ctx_face = face.getContext("2d");
    canvas = document.getElementById("output");

    if (isMobile) {
      video1.width = 320;
      video1.height = 320;
      canvas.width = 320;
      canvas.height = 320;
    } else {
      video1.width = 640;
      video1.height = 480;
      canvas.width = 640;
      canvas.height = 480;
    }
    ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
  };

  const onResult = (results) => {
    let distanceNoseToCenter, pixel;

    canvasElement = document.getElementById("canvas2");
    canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.canvas.width = isMobile ? 320 : 640;
    canvasCtx.canvas.height = isMobile ? 320 : 480;
    faceInFrame = results.detections.length;
    if (results.detections.length > 1) {
      setBlazeMessage("Nhiều hơn 1 khuôn mặt trong camera");
      setCameraAvail(false);
    } else if (results.detections.length === 0) {
      setBlazeMessage("Không xuất hiện khuôn mặt trong camera");
      setCameraAvail(false);
    } else {
      let height =
        results.detections[0].boundingBox.height * (isMobile ? 320 : 480);
      let width =
        results.detections[0].boundingBox.width * (isMobile ? 320 : 640);

      let landmarks = results.detections[0].landmarks.map((item) => {
        return [
          item.x * (isMobile ? 320 : 640),
          item.y * (isMobile ? 320 : 480),
        ];
      });

      const nose = landmarks[2];
      distanceNoseToCenter = isMobile
        ? distance_2_point(nose, [160, 160])
        : distance_2_point(nose, [320, 240]);

      const boundingBox = results.detections[0].boundingBox;
      let bottomRight = [
        boundingBox.xCenter * (isMobile ? 320 : 640) + width / 2,
        boundingBox.yCenter * (isMobile ? 320 : 480) + height / 2,
      ];
      let topLeft = [
        boundingBox.xCenter * (isMobile ? 320 : 640) - width / 2,
        boundingBox.yCenter * (isMobile ? 320 : 480) - height / 2,
      ];
      let cropWidth = topLeft[0] - bottomRight[0];
      let cropHeight = bottomRight[1] - topLeft[1];
      canvasCtx.drawImage(
        results.image,
        isMobile
          ? bottomRight[0] + cropWidth * 0.3 + 320 / 4
          : bottomRight[0] + cropWidth * 0.3 + 640 / 7,
        topLeft[1] - cropHeight * 0.5,
        cropWidth * 1.6,
        cropHeight * 1.8,
        0,
        0,
        256,
        256
      );
      const right_ear = landmarks[4];
      const left_ear = landmarks[5];
      if (distanceNoseToCenter > 100) {
        setBlazeMessage("Đặt khuôn mặt vào chính giữa");
        setCameraAvail(false);
      } else {
        setBlazeMessage("Có thể bấm quay");
        setCameraAvail(true);
      }
      let left_distance = distance_2_point(left_ear, nose);
      let right_distance = distance_2_point(right_ear, nose);
      let ratio = left_distance / right_distance;
      //0 - center 1 - left 2 - right
      if (ratio > 2.2) {
        //turn right
        direction = 2;
      } else if (ratio < 0.45) {
        //turn leftpredictions
        direction = 1;
      } else {
        direction = 0;
      }

      faceRatio = results.detections[0].boundingBox.height;
      if (faceRatio > 0.46) {
        setCameraAvail(false);
        setBlazeMessage("Bạn hiện đang ở quá gần. Hãy lùi lại");
      } else if (faceRatio < 0.25) {
        setCameraAvail(false);
        setBlazeMessage("Bạn đang ở quá xa. Hãy lại gần");
      }

      pixel = canvasCtx.getImageData(0, 0, 256, 256);
      if (pixel) {
        pixelData = pixel.data;
      }
    }
    canvasCtx.restore();
  };

  useEffect(() => {
    const main = () => {
      let videoElement = document.querySelector("video");
      const faceDetection = new window.FaceDetection({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
        },
      });
      faceDetection.setOptions({
        model: "short",
        minDetectionConfidence: 0.5,
      });
      faceDetection.onResults(onResult);
      const doSomethingWithTheFrame = async (now, metadata) => {
        // Do something with the frame.
        // console.log(now, metadata);
        await faceDetection.send({ image: videoElement });
        // Re-register the callback to be notified about the next frame.
        videoElement.requestVideoFrameCallback(doSomethingWithTheFrame);
      };
      // Initially register the callback to be notified about the first frame.
      videoElement.requestVideoFrameCallback(doSomethingWithTheFrame);
    };
    try {
      main();
    } catch (error) {
      throw error;
    }
  }, []);

  //Check face direction
  const addFaceDirectionToArray = () => {
    setInterval(() => {
      directionList.push(direction);
      if (directionList.length === 16) directionList.shift();
      setDirectionArray(directionList);
    }, 200);
  };

  //Add frame to checking by blaze model array while recording
  const countFrame = () => {
    setInterval(() => {
      countFaceArray.push(faceInFrame);
      faceRatioArray.push(faceRatio);
      if (faceRatioArray.length === 6) {
        faceRatioArray.shift();
      }
      if (countFaceArray.length === 6) {
        countFaceArray.shift();
      }
      setFaceRatioList(faceRatioArray);
      setFaceNumber(countFaceArray);
    }, 50);
  };

  const distance_2_point = (point_1, point_2) => {
    let x1 = point_1[0];
    let y1 = point_1[1];
    let x2 = point_2[0];
    let y2 = point_2[1];
    let distance = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    return distance;
  };

  //Get label from face model
  const getLabelFace = async (data, modelFace) => {
    let result = arrayToRgbArray(data);
    let processedImage = tf.tensor3d(result);
    const prediction = await modelFace.predict(
      tf.reshape(processedImage, [-1, 256, 256, 3])
    );
    const label = prediction.argMax(1).dataSync()[0];
    return label;
  };

  //Add label from face model to an array for checking valid face from face model
  const addLabelToArray = (modelFace) => {
    //Get modelFace from initModelFace function
    //Because useState dont work in initModelFace so I have to do like this
    setInterval(async () => {
      let label = await getLabelFace(pixelData, modelFace);
      faceModelLabelList.push(label);
      // setBlazeMessage(label)
      if (faceModelLabelList.length === 24) {
        faceModelLabelList.shift();
      }
      setFaceModelLabelArray(faceModelLabelList);
    }, 300);
  };

  const checkValidAfterRecord = (array) => {
    if (isStarted) {
      let countInvalid = 0;
      array.forEach((element) => {
        if (element !== 0) countInvalid++;
      });
      let rate = (countInvalid / array.length) * 100;
      if (rate > 50) {
        forceUpdate();
        toast.error("Video bạn đã quay không hợp lệ. Hãy thực hiện lại!");
      } else {
        console.log("Record done");
        setIsStopped(true);
      }
    }
  };

  const checkFaceDirection = (array, direction) => {
    let countInvalid = 0;
    array.forEach((element) => {
      if (element !== direction) countInvalid++;
    });
    if (countInvalid >= 14) return false;
    return true;
  };

  const arrayToRgbArray = (data) => {
    let input = [];
    for (let i = 0; i < 256; i++) {
      input.push([]);
      for (let j = 0; j < 256; j++) {
        input[i].push([]);
        input[i][j].push(data[(i * 256 + j) * 4]);
        input[i][j].push(data[(i * 256 + j) * 4 + 1]);
        input[i][j].push(data[(i * 256 + j) * 4 + 2]);
      }
    }
    return input;
  };

  //For recording video
  let media_recorder;
  let blobs_recorded = [];

  const webcamRef = React.useRef(null);

  //Start recording video
  const startRecord = async () => {
    setIsStarted(true);

    setOrderMessage("Giữ thẳng");
    // set MIME type of recording as video/webm
    if (isIOS || navigator?.platform.indexOf("Mac") > -1) {
      media_recorder = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/mp4",
      });
    } else {
      media_recorder = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
    }
    media_recorder.addEventListener("dataavailable", function (e) {
      blobs_recorded.push(e.data);
      // setBlobArray(blobs_recorded);
    });
    //Start add frame to blob
    media_recorder.start(1000);
    // setVideoFile("This is file");
    //Stop add frame to blob
    setTimeout(() => {
      media_recorder.stop();
      let file = new File(blobs_recorded, "record.mp4", {
        type: "video/mp4",
        lastModified: new Date().getTime(),
      });
      setVideoFile(file);
    }, 7500);
  };

  //Get data after call API
  const getVideoData = async (file) => {
    const url = URL_API + "register_user_video";
    // toast.error(url);
    let formData = new FormData();
    formData.append("video", file);
    formData.append("session_id", sessionId);
    formData.append("check_liveness", "False");
    formData.append("force_register", "False");
    formData.append("exclude", "embedding,created");
    formData.append("source", "Spinel");
    formData.append("force_replace", "True");
    formData.append("threshold", "0.75");

    let req;
    //Send FormData to API with axios
    if (file) {
      req = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data",'Authorization':'Token e240185e82954dca453a265c84433d3cd4bf9601' },
        timeout: 15000,
      }).catch((e) => {
        console.log("error", e);
        toast.error(
          "Lỗi hệ thống. Vui lòng thử lại. Do không call được API " + e
        );
        forceUpdate();
      });
      if (req.data.code === "ERROR") {
        if (req.data.error === "Face card not matched") {
          toast.error(
            "Khuôn mặt của bạn trong video và ở trên thẻ không giống nhau. Hãy thực hiện lại!"
          );
          forceUpdate();
        } else if (req.data.code === "E310") {
          toast.error("Mặt đăng kí hỏng do nhắm mắt. Hãy thử lại!");
          forceUpdate();
        } else if (req.data.code === "E314") {
          toast.error("Video chất lượng kém. Hãy thử lại!");
          forceUpdate();
        } else if (req.data.code === "E997") {
          toast.error("Liveness video không thành công. Hãy thử lại!");
          forceUpdate();
        }
      }
      if (req.data.code === "SUCCESS") {
        confirmData(req.data.output.card_front.result);
        goConfirmPhase();
      }
    } else {
      // toast.error("Lỗi hệ thống. Vui lòng thử lại. Do không lấy được video");
      forceUpdate();
      console.log("run else");
    }
  };

  //Check invalid frame while recording by blazemodel
  useEffect(() => {
    //Set interval for checking valid frame while recording
    const intervalCheck = setInterval(() => {
      if (isStarted) {
        if (faceNumber.find((e) => e === 1) === undefined) {
          toast.error(
            "Thực hiện quay thất bại do bạn rời khỏi khung hình hoặc có nhiều người trong khung hình quá thời gian quy định. Vui lòng thử lại"
          );
          forceUpdate();
          clearTimeout(keepCenter);
          clearTimeout(turnLeft);
          clearTimeout(turnRight);
          clearTimeout(checkCenter);
          clearTimeout(checkLeft);
          clearTimeout(checkRight);
          clearTimeout(checkAfterRecord);
          clearInterval(intervalCheck);
        } else if (
          faceRatioList.find((e) => (e >= 0.25) & (e <= 0.46)) === undefined
        ) {
          toast.error(`faceRatioList[0] : ${faceRatioList[0]}`);
          forceUpdate();
          clearTimeout(checkCenter);
          clearTimeout(checkLeft);
          clearTimeout(checkRight);
          clearTimeout(keepCenter);
          clearTimeout(turnLeft);
          clearTimeout(turnRight);
          clearTimeout(checkAfterRecord);
          clearInterval(intervalCheck);
        } else
          setTimeout(() => {
            clearInterval(intervalCheck);
          }, 7000);
      }
    }, 1000);

    // Check face direction

    const checkCenter = setTimeout(() => {
      if (isStarted) {
        if (!checkFaceDirection(directionArray, 0)) {
          toast.error("Khuôn mặt bạn không quay đúng hướng. Hãy thực hiện lại");
          forceUpdate();
          clearTimeout(checkLeft);
          clearTimeout(checkRight);
          clearTimeout(keepCenter);
          clearTimeout(turnLeft);
          clearTimeout(turnRight);
          clearTimeout(checkAfterRecord);
          clearInterval(intervalCheck);
        }
      }
    }, 1000);

    const checkLeft = setTimeout(() => {
      if (isStarted) {
        if (!checkFaceDirection(directionArray, 1)) {
          toast.error("Khuôn mặt bạn không quay đúng hướng. Hãy thực hiện lại");
          forceUpdate();
          clearTimeout(checkRight);
          clearTimeout(keepCenter);
          clearTimeout(turnLeft);
          clearTimeout(turnRight);
          clearTimeout(checkAfterRecord);
          clearInterval(intervalCheck);
        }
      }
    }, 4000);

    const checkRight = setTimeout(() => {
      if (isStarted) {
        if (!checkFaceDirection(directionArray, 2)) {
          toast.error("Khuôn mặt bạn không quay đúng hướng. Hãy thực hiện lại");
          forceUpdate();
          clearTimeout(checkAfterRecord);
          clearTimeout(keepCenter);
          clearTimeout(turnLeft);
          clearTimeout(turnRight);
          clearInterval(intervalCheck);
        }
      }
    }, 7000);

    //Set timeout for progress bar animation
    const keepCenter = setTimeout(() => {
      if (isStarted) {
        setOrderMessage("Quay trái");
        setPhaseStatus({ ...phaseStatus, center: "success" });
      } else {
        clearTimeout(turnLeft);
        clearTimeout(turnRight);
      }
    }, 1000);

    const turnLeft = setTimeout(() => {
      if (isStarted) {
        setOrderMessage("Quay phải");
        setPhaseStatus({ ...phaseStatus, center: "success", left: "success" });
      } else {
        clearTimeout(turnRight);
      }
    }, 4000);

    const turnRight = setTimeout(() => {
      if (isStarted) {
        setOrderMessage("Đã xong");
        setPhaseStatus({
          center: "success",
          left: "success",
          right: "success",
        });
      }
    }, 7000);

    const checkAfterRecord = setTimeout(() => {
      if (isStarted) {
        checkValidAfterRecord(faceModelLabelArray);
      }
    }, 8000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStarted]);

  useEffect(() => {
    if (isStopped) {
      getVideoData(videoFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStopped]);

  return (
    <div className="front-card-snap-phase">
      <div className="container">
        <div className="phase-title">
          <div className="step-description">
            <span className="step">Bước 3:</span> Kiểm tra độ hợp lệ khuôn mặt
          </div>
          <p className="user-step-noti">Cử động khuôn mặt theo hướng dẫn</p>
        </div>
        <div className="video-field face-video">
          {isStarted ? (
            <img src={normalLayer} alt="subtract_image" id="card-overlayer" />
          ) : cameraAvail ? (
            <img src={availLayer} alt="subtract_image" id="card-overlayer" />
          ) : (
            <img src={normalLayer} alt="subtract_image" id="card-overlayer" />
          )}

          {!isStarted ? (
            <p className="blaze-message">{blazeMessage}</p>
          ) : (
            <p className="order-message">{orderMessage}</p>
          )}
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={videoConstraints}
          />

          <canvas id="output"></canvas>
          <canvas id="face"></canvas>

          {isStarted ? (
            <div className="processing">
              <div className="loading-animate"></div>
            </div>
          ) : cameraAvail ? (
            <div className="camera-button" onClick={startRecord}>
              <Icon.Video />
            </div>
          ) : (
            <button className="camera-button-disable">
              <Icon.Video />
            </button>
          )}

          {isStarted ? (
            <div className="facevideo-stepbar">
              {phaseStatus.center === "success" ? (
                <div className="face-center-done">1</div>
              ) : (
                <div className="face-center">1</div>
              )}

              <div className="connect">
                {phaseStatus.center === "success" ? (
                  <div className="sub-connect"></div>
                ) : (
                  <div className=" success"></div>
                )}
              </div>

              {phaseStatus.left === "success" ? (
                <div className="face-left-done">2</div>
              ) : (
                <div className="face-left">2</div>
              )}

              <div className="connect">
                {phaseStatus.left === "success" ? (
                  <div className=" sub-connect"></div>
                ) : (
                  <div className="success"></div>
                )}
              </div>

              {phaseStatus.right === "success" ? (
                <div className="face-right-done">3</div>
              ) : (
                <div className="face-right">3</div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <canvas id="canvas2" height="210px" width="280px"></canvas>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Faceliveness;
