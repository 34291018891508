import React, { useState, useEffect } from "react";

const Confirm = ({ confirmData }) => {
  const [data, setData] = useState({
    name: "",
    sex: "",
    dob: "",
    province: "",
    district: "",
    wards: "",
    address: "",
  });

  useEffect(() => {
    if (confirmData) {
      setData({
        ...data,
        name: confirmData.ho_ten.value,
        sex: confirmData.gioi_tinh ? confirmData.gioi_tinh.value : 'Không xác định',
        dob: confirmData.ngay_sinh.value,
        province: confirmData.ho_khau_thuong_tru.normalized.tinh.value,
        district: confirmData.ho_khau_thuong_tru.normalized.huyen.value,
        wards: confirmData.ho_khau_thuong_tru.normalized.xa.value,
        address: confirmData.ho_khau_thuong_tru.normalized.value,
      });
    }
  }, []);

  const handleInputData = (e) => {
    const value = e.target.value;
    setData({ ...data, [e.target.name]: value });
  };

  const sendConfirmData = () => {
    console.log("data", data);
  };

  return (
    <div className="front-card-snap-phase">
      <div className="container">
        <div className="phase-title">
          <div className="step-description">
            <span className="step">Bước 4:</span> Xác nhận thông tin
          </div>
        </div>
        <form id="confirm-form" onSubmit={sendConfirmData}>
          <div className="data-field row">
            <div className="input-field col-lg-12 col-sm-12">
              <label className="input-label">HỌ VÀ TÊN</label>
              <br />
              <input
                type="text"
                name="name"
                value={data.name}
                onChange={handleInputData}
              />
            </div>

            <div className="input-field col-lg-6 col-sm-6">
              <label className="input-label">GIỚI TÍNH</label>
              <br />
              <input
                type="text"
                name="sex"
                value={data.sex}
                onChange={handleInputData}
              />
            </div>

            <div className="input-field col-lg-6 col-sm-6">
              <label className="input-label">NGÀY SINH</label>
              <br />
              <input
                type="text"
                name="dob"
                value={data.dob}
                onChange={handleInputData}
              />
            </div>

            <span className="address-text">
              ĐỊA CHỈ THƯỜNG TRÚ ________________
            </span>

            <div className="input-field col-lg-12 col-sm-12">
              <label className="input-label">TỈNH THÀNH PHỐ</label>
              <br />
              <input
                type="text"
                name="province"
                value={data.province}
                onChange={handleInputData}
              />
            </div>

            <div className="input-field col-lg-6 col-sm-12">
              <label className="input-label">QUẬN HUYỆN</label>
              <br />
              <input
                type="text"
                name="district"
                value={data.district}
                onChange={handleInputData}
              />
            </div>

            <div className="input-field col-lg-6 col-sm-12">
              <label className="input-label">PHƯỜNG/XÃ</label>
              <br />
              <input
                type="text"
                name="wards"
                value={data.wards}
                onChange={handleInputData}
              />
            </div>

            <div className="input-field col-lg-12 col-sm-12">
              <label className="input-label">ĐỊA CHỈ CHI TIẾT</label>
              <br />
              <input
                type="text"
                name="address"
                value={data.address}
                onChange={handleInputData}
              />
            </div>

            <div className="col-lg-12 col-sm-12 button-field">
              <div>
                <button
                  className="snap-again-button"
                >
                  Chụp lại
                </button>
              </div>
              <div>
                <button className="continue-step-button" type="submit">
                  Tiếp tục
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Confirm;
