const messageModelCard = [
  'Không xuất hiện thẻ trong khung hình',
  'Mặt trước CCCD hợp lệ',
  'Mặt sau CCCD hợp lệ',
  'Mặt trước CMND hợp lệ',
  'Mặt sau CMND hợp lệ',
  'Tay che mặt trước CCCD',
  'Tay che mặt sau CCCD',
  'Mặt trước CCCD mất cạnh',
  'Mặt sau CCCD mất cạnh',
  'Mặt trước CCCD bị lóa',
  'Mặt sau CCCD bị lóa',
  'Tay che mặt trước CMND',
  'Tay che mặt sau CMND',
  'Mặt trước CMND mất cạnh',
  'Mặt sau CCCD mất cạnh',
  'Mặt trước CCCD bị lóa',
  'Mặt sau CCCD bị lóa',
  'Mặt trước CCCD hợp lệ', //For chip card
  'Mặt sau CCCD hợp lệ',  //For chip card
  'Mặt trước CCCD bị lóa', //For chip card
  'Mặt sau CCCD bị lóa', //For chip card
  'Tay che mặt trước CCCD', //For chip card
  'Tay che mặt sau CCCD', //For chip card
  'Mặt trước CCCD mất cạnh', //For chip card
  'Mặt sau CCCD mất cạnh', //For chip card
  'Hộ chiếu hợp lệ'
]

const messageModelFace = ['VALID', 'HAND', ' MASK', 'SUNGLASSES', 'COVER']

const URL_API = "https://ekyc-pvcombank.tunnel.techainer.com/api/v1/ekyc/"
const FIRST = [
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ]
  ],
  [
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          1,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ],
  [
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          2,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          1,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ],
      [
          0,
          0,
          0
      ]
  ]
]

export { messageModelCard, messageModelFace, URL_API,FIRST };