import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import subtract from "../../static/image/Subtract.png";
import * as tf from "@tensorflow/tfjs";
import { messageModelCard, URL_API, FIRST } from "../../constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import { createCanvas } from "canvas";

const Front = ({ goNextPhase, isMobile, sessionId }) => {
  const [isProcessing, setProcessing] = useState(false);
  const [isValid, setValid] = useState(false);
  const [model, setModel] = useState();
  const [frontCardInfo, setFrontInfo] = useState({
    gtttNumber: "",
    name: "",
    dateOfBirth: "",
  });

  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
    height: 480,
    facingMode: "environment",
  });

  //Chay model o tren local
  const modelURL = "../../model_card/model.json";

  let arrayToRgbArray = (data) => {
    let input = [];
    for (let i = 0; i < 210; i++) {
      input.push([]);
      for (let j = 0; j < 280; j++) {
        input[i].push([]);
        input[i][j].push(data[(i * 280 + j) * 4]);
        input[i][j].push(data[(i * 280 + j) * 4 + 1]);
        input[i][j].push(data[(i * 280 + j) * 4 + 2]);
      }
    }
    return input;
  };

  const initModel = async () => {
    if (!model) {
      let x = await tf.loadGraphModel(modelURL);
      let processedImage = tf.tensor3d(FIRST);
      x.predict(tf.reshape(processedImage, [-1, 210, 280, 3]));
      setModel(x);
    }
  };

  //First setup for layer
  const setupLayer = () => {
    let overlay = document.getElementById("card-overlayer");
    if (overlay) {
      if (isMobile) {
        overlay.style.width = 320 + "px";
        overlay.style.height = 240 + "px";
      } else {
        overlay.style.width = 640 + "px";
        overlay.style.height = 480 + "px";
      }
    }
  };

  //Change video size if isMobile
  useEffect(() => {

    let agent = navigator.userAgent;
    let isWebkit = agent.indexOf("AppleWebKit") > 0;
    let isIPad = agent.indexOf("iPad") > 0;
    let isIOS = agent.indexOf("iPhone") > 0 || agent.indexOf("iPod") > 0;
    let isAndroid = agent.indexOf("Android") > 0;
    let isNewBlackBerry =
      agent.indexOf("AppleWebKit") > 0 && agent.indexOf("BlackBerry") > 0;
    let isWebOS = agent.indexOf("webOS") > 0;
    let isWindowsMobile = agent.indexOf("IEMobile") > 0;
    let isSmallScreen =
      window.screen.width < 767 || (isAndroid && window.screen.width < 1000);
    let isUnknownMobile = isWebkit && isSmallScreen;
    let isMobile =
      isIOS ||
      isAndroid ||
      isNewBlackBerry ||
      isWebOS ||
      isWindowsMobile ||
      isUnknownMobile;
    let isTablet = isIPad || (isMobile && !isSmallScreen);
    if (isTablet || isMobile) {
      setVideoConstraints({ ...videoConstraints, width: 360, height: 480 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Change layer size when video size change
  useEffect(() => {
    let overlay = document.getElementById("card-overlayer");
    if (overlay) {
      if (isMobile) {
        overlay.style.width = 320 + "px";
        overlay.style.height = 240 + "px";
      } else {
        overlay.style.width = 640 + "px";
        overlay.style.height = 480 + "px";
      }
    }
  }, [isMobile]);

  //Set up page when access
  useEffect(() => {
    const setup = async () => {
      setupLayer();
      await initModel();
    };
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Handle snap camera, get image data, convert and push into model then get label
  const handleSnap = async () => {
    setProcessing(true);
    let video1 = document.querySelector("video");
    let canvas = document.getElementById("canvas1");
    let canvasFullSize = createCanvas(video1.videoWidth, video1.videoHeight);
    let context = canvas.getContext("2d");
    let contextFullSize = canvasFullSize.getContext("2d");
    context.drawImage(video1, 0, 0, 280, 210);
    contextFullSize.drawImage(video1, 0, 0);
    let pixel = context.getImageData(0, 0, 280, 210);
    let data = pixel?.data;
    let result = arrayToRgbArray(data);
    const before = Date.now();
    let processedImage = await tf.tensor3d(result);
    const prediction = await model.predict(
      tf.reshape(processedImage, [-1, 210, 280, 3])
    );
    const after = Date.now();
    console.log(`${after - before}ms`);
    toast.info("Model xử lí mất: " + (after - before) + "ms");
    const label = prediction.argMax(1)?.dataSync()[0];
    if (label === 1 || label === 3 || label === 17 || label === 25) {
      await getFrontCardData(canvasFullSize);
      setProcessing(false);
      setValid(true);
    } else if (label !== 0 && label % 2 === 0) {
      setTimeout(() => {
        toast.error("Sai mặt thẻ");
        snapAgain();
      }, 1000);
    } else {
      setTimeout(() => {
        toast.error(messageModelCard[label]);
        snapAgain();
      }, 1000);
    }
  };

  //Reset all state
  const snapAgain = () => {
    //Reset state
    setValid(false);
    setProcessing(false);
    setFrontInfo({ gtttNumber: "", name: "", dateOfBirth: "" });
    setupLayer();
    if (isMobile) {
      setVideoConstraints({ ...videoConstraints, width: 480, height: 640 });
    } else
      setVideoConstraints({ ...videoConstraints, width: 640, height: 480 });
    //Clear canvas
    let canvas = document.getElementById("canvas1");
    let context = canvas.getContext("2d");
    context.clearRect(0, 0, 280, 210);
  };

  //Go to snap back card phase
  const snapBackCard = () => {
    goNextPhase(2);
  };

  //Call API
  const getFrontCardData = async (canvas) => {
    const url = URL_API + "register_user_card";
    let fileImage = dataURLtoFile(canvas.toDataURL(), "image.png");
    //Append data into FormData
    let formData = new FormData();
    formData.append("image", fileImage);
    formData.append("session_id", sessionId);
    formData.append("type", "FR");
    formData.append("check_liveness", "False");
    formData.append("force_register", "False");
    formData.append("exclude", "embedding,created");
    formData.append("source", "Spinel");
    formData.append("force_replace", "True");
    let req;
    //Send FormData to API with axios

   
    if (fileImage) {

      req = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data",'Authorization':'Token e240185e82954dca453a265c84433d3cd4bf9601' },
      }).catch((e) => {
        console.log("error", e);
        snapAgain();
        toast.error("Lỗi hệ thống. Vui lòng thử lại!");
      });
      if (req?.data.code !== "SUCCESS") {
        if (req?.data.code === "E008") {
          toast.error("Không xuất hiện khuôn mặt trong thẻ!");
        } else if (req?.data.code === "E102") {
          toast.error("Ảnh chụp giấy tờ tùy thân bị mờ. Hãy thử lại!");
        } else if (req?.data.code === "E103") {
          toast.error("Thẻ bạn chụp cần phải là giấy tờ tùy thân!");
        } else if (req?.data.code === "E013") {
          toast.error("Không xuất hiện thẻ trong khung hình");
        } else {
          toast.error("Đã xảy ra lỗi. Vui lòng thử lại");
        }
        setTimeout(() => snapAgain(), 500);
      }
      //Show data get from API
      else fillFrontCardData(req?.data.output.result);
    } else {
      toast.error("No image");
    }
  };

  //Convert canvas to img png
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  //Fill data into input field from API
  const fillFrontCardData = (data) => {
    setFrontInfo({
      gtttNumber: data.id.value,
      name: data.ho_ten.value,
      dateOfBirth: data.ngay_sinh.value,
    });
  };

  //Handle input data change
  const handleFrontCardData = (e) => {
    const value = e.target.value;
    setFrontInfo({
      ...frontCardInfo,
      [e.target.name]: value,
    });
  };

  return (
    <div className="front-card-snap-phase">
      <div className="container">
        <div className="phase-title">
          <div className="step-description">
            <span className="step">Bước 1:</span> Chụp mặt trước giấy tờ tùy
            thân
          </div>
          <p className="user-step-noti">
            Chắc chắn rằng ảnh sau khi chụp không bị mờ, che khuất thông tin
          </p>
        </div>
        {!isProcessing && !isValid ? (
          <div className="video-field">
            <img src={subtract} alt="subtract_image" id="card-overlayer" />
            <Webcam audio={false} videoConstraints={videoConstraints} />
            <div className="camera-button" onClick={handleSnap}>
              <Icon.Camera />
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* Capture image area */}
        <div className="canvas-frame">
          <canvas id="canvas1" height="210px" width="280px"></canvas>
          {isProcessing ? (
            <div className="processing">
              <div className="loading-animate"></div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Info output area */}
        {isValid ? (
          <form onSubmit={snapBackCard}>
            <div className="data-field row">
              <div className="input-field col-12">
                <label className="input-label">Số GTTT</label>
                <br />
                <input
                  type="text"
                  name="gtttNumber"
                  value={frontCardInfo.gtttNumber}
                  onChange={handleFrontCardData}
                />
              </div>
              <div className="input-field col-12">
                <label className="input-label">Họ và tên</label>
                <br />
                <input
                  type="text"
                  name="name"
                  value={frontCardInfo.name}
                  onChange={handleFrontCardData}
                />
              </div>
              <div className="input-field col-12">
                <label className="input-label">Ngày sinh</label>
                <br />
                <input
                  type="text"
                  name="dateOfBirth"
                  value={frontCardInfo.dateOfBirth}
                  onChange={handleFrontCardData}
                />
              </div>
              <div className="col-12 button-field">
                <div>
                  <button className="snap-again-button" onClick={snapAgain}>
                    Chụp lại
                  </button>
                </div>
                <div>
                  <button className="continue-step-button" type="submit">
                    Tiếp tục
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default Front;
